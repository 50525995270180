import {forwardRef, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {getDynamicValue} from '../functions/util'
import {CourseBox} from '../components/courseBox'
import CompetencyGraph from '../components/competencyGraph'
import {
  CompetencyListResponse,
  JobRoleCompetency,
  ModifiedCompetenciesObj,
  ModifiedCompetencyItem,
} from '../types/careerPageTypes'

interface props {
  data: CompetencyListResponse
}

const extractCompetencies = (data: CompetencyListResponse) => {
  let competencies: ModifiedCompetenciesObj = {}

  if (data) {
    const {jobRoleCompetencyList, profilMergedCompetencyList, targetJobRoleCompetencyList} = data

    let currentCompArr: JobRoleCompetency[] = []
    let targetCompArr: JobRoleCompetency[] = []
    if (jobRoleCompetencyList && profilMergedCompetencyList) {
      currentCompArr = profilMergedCompetencyList
      targetCompArr = jobRoleCompetencyList
    }
    if (jobRoleCompetencyList && targetJobRoleCompetencyList) {
      currentCompArr = jobRoleCompetencyList
      targetCompArr = targetJobRoleCompetencyList
    }

    currentCompArr
      .filter((jobRole) => jobRole?.level > 0)
      .forEach((jobRole) => {
        const competencyId = jobRole?.competencyId
        const category = jobRole?.category
        const competencyName = jobRole?.competency?.name
        const actualLevel = jobRole?.level

        if (!competencies[category]) competencies[category] = []
        competencies[category].push({competencyId, competencyName, actualLevel, requiredLevel: 0})
      })

    targetCompArr
      .filter((jobRole) => jobRole?.level > 0)
      .forEach((jobRole) => {
        const competencyId = jobRole?.competencyId
        const category = jobRole?.category
        const competencyName = jobRole?.competency?.name
        const requiredLevel = jobRole?.level

        if (!competencies[category]) competencies[category] = []
        const index = competencies[category].findIndex(
          (item: ModifiedCompetencyItem) => item.competencyId === competencyId
        )
        if (index >= 0) {
          competencies[category][index].requiredLevel = requiredLevel
        } else {
          competencies[category].push({competencyId, competencyName, requiredLevel, actualLevel: 0})
        }
      })
  }
  return competencies
}

const MyCompetencyLevels = forwardRef<HTMLDivElement, props>(({data}, posRef) => {
  const intl = useIntl()

  const overallMatchRate = parseFloat(data?.percentage || '0')
  const competencies = useMemo(() => extractCompetencies(data), [data])

  const recommendedTrainings =
    data?.requiredTrainingList && data?.requiredTrainingList.length > 0
      ? data.requiredTrainingList
      : null

  return (
    <div className='mt-12' ref={posRef}>
      <div className='myCourse_overview titlePrimary'>
        <h2 className='long'>{intl.formatMessage({id: 'POPLEADS.ACTUAL_VS_REQUIRED'})}</h2>
      </div>
      <div className='d-flex flex-wrap' style={{gap: "12px 48px"}}>
        <div className='graphLabel actual'>Actual Competency Level</div>
        <div className='graphLabel necessary'>Required Competency Level</div>
      </div>
      <div className='competencyGraph mt-8' style={{maxWidth: '550px'}}>
        <h3 className='competency-title'>{intl.formatMessage({id: 'POPLEADS.MATCHING'})}</h3>
        <div className='competency'>
          <div className='requiredLevel' style={{width: '100%'}} />
          <div
            className='actualLevel d-flex justify-content-center align-items-center'
            style={{width: `${overallMatchRate}%`}}
          />
          <div
            className='levelName fw-bolder ps-0'
            style={{width: `${overallMatchRate}%`, minWidth: '6%'}}
          >{`${overallMatchRate}%`}</div>
        </div>
      </div>

      <div className='row justify-content-between align-items-end mt-6'>
        {Object.keys(competencies)?.map((category: string) => (
          <div key={category} className='col-lg-6 mt-6' style={{maxWidth: '550px'}}>
            <CompetencyGraph category={category} competencies={competencies[category]} />
          </div>
        ))}
      </div>
      {recommendedTrainings && (
        <div className='mt-12'>
          <h3 className='competency-title'>
            {intl.formatMessage({id: 'POPLEADS.RECOMMENDED_TRAINING'})}
          </h3>
          <div className='allCoursesGrid mt-6 mx-0'>
            {recommendedTrainings.map((course: any) => (
              <CourseBox
                key={course.id}
                title={course.campaignName}
                subtitle={getDynamicValue(course?.campaign?.columnsList, 'Short Description')}
                hour={getDynamicValue(course?.campaign?.columnsList, 'Video Hours')}
                lesson={getDynamicValue(course?.campaign?.columnsList, 'Number of Lectures')}
                primary={
                  (course?.campaign?.basTarih !== null &&
                    new Date() > new Date(course?.campaign?.basTarih)) ||
                  course?.campaign?.basTarih === null
                }
                primaryText={intl.formatMessage({id: 'POPLEADS.GO_TO_COURSE'})}
                imgUrl={course.imgUrl}
                basTarih={course?.campaign?.basTarih}
                id={course.courseId}
                allCourses={true}
                link={course?.campaign?.link}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
})

export default MyCompetencyLevels
