/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { useIntl } from 'react-intl'

const AllCoursesNoCourse: FC = () => {
    const intl = useIntl()

    return (
        <div className='container'>
            <div className='row noCourse'>
                    <h1 className='text-center text-lg-start'>{intl.formatMessage({ id: 'POPLEADS.NO_COURSE' })}</h1>
                    <p className='text-center text-lg-start'> {intl.formatMessage({ id: 'POPLEADS.NO_COURSE_INFO' })}</p>
            </div>
        </div>
    )
}

export { AllCoursesNoCourse }
