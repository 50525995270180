import React, {useState} from 'react'

export const ConfigDataContext = React.createContext<any>({configData: {}, setConfigData: () => {}})

const ConfigDataProvider: React.FC<React.ReactNode> = ({children}) => {
  
  const [configData, _setConfigData] = useState()

  const setConfigData = (data: any) => _setConfigData(data)

  return (
    <ConfigDataContext.Provider value={{configData, setConfigData}}>
      {children}
    </ConfigDataContext.Provider>
  )
}

export default ConfigDataProvider
