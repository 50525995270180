import React, { FC, useEffect, useRef, useState } from 'react'
import { SearchComponent } from '../../../assets/ts/components'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { post } from "../../../../app/popleads/networking/requestService"
import { DebounceInput } from 'react-debounce-input';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl'
import { MenuComponent } from "../../../assets/ts/components/MenuComponent"

const Search: FC = () => {
  const [menuState, setMenuState] = useState<"main" | "advanced" | "preferences">("main");
  const element = useRef<HTMLDivElement | null>(null);
  const wrapperElement = useRef<HTMLDivElement | null>(null);
  const intl = useIntl();
  const resultsElement = useRef<HTMLDivElement | null>(null);
  const suggestionsElement = useRef<HTMLDivElement | null>(null);
  const emptyElement = useRef<HTMLDivElement | null>(null);
  const [searchResults, setSearchResults] = useState([]);

  const searchCourse = (input: any) => {
    if (input !== "") {
      const spinner = document.getElementById("spinnerSearch")
      if (spinner) spinner.classList.remove("d-none");
      const requestObj = {
        "fn": "campaignListHomePage",
        status: ["Active", "Planning"],
        start: 1,
        rowsPerPage: 1000,
        isExport: 0,
        orderByColumn: "basTarih",
        orderType: "asc",
        campName: "*" + input + "*",
        isFromFeatured: 1,
        filterValuesList: '[{"fieldName":"campaign_is_deleted","fieldValue":"0","fieldType":"calculated","fieldFormat":"number","fieldSign":"equal"},{"fieldName":"campaign_course_id","fieldValue":"0","fieldType":"calculated","fieldFormat":"string","fieldSign":"notequal"}]'
      };
      post(requestObj)
        .then(({ data: { campaignList } }) => {
          setSearchResults(campaignList);
          // console.log("campaignList: ", campaignList);
          if (spinner) spinner.classList.add("d-none");

          if (campaignList.length === 0) {
            emptyElement.current!.classList.remove('d-none');
            resultsElement.current!.classList.add('d-none');
          }
          else {
            emptyElement.current!.classList.add('d-none');
            resultsElement.current!.classList.remove('d-none');
          }

        })
        .catch((err) => {
          //setError({ text: err.response.text, code: err.response.status });
          //setLoading(false);
          if (spinner) spinner.classList.add("d-none");
        });
    }
    else {
      setSearchResults([]);
      emptyElement.current!.classList.add('d-none');
      resultsElement.current!.classList.remove('d-none');
    }
  }

  useEffect(() => {
    // Initialize search handler
    const searchObject = SearchComponent.createInsance('#kt_header_search');

  }, [])

  return (
    <>
      <div
        id='kt_header_search'
        className='d-flex align-items-stretch searchBox'
        data-kt-search-keypress='false'
        data-kt-search-min-length='2'
        data-kt-search-enter='false'
        data-kt-search-layout='menu'
        data-kt-menu-trigger='auto'
        data-kt-menu-overflow='false'
        data-kt-menu-permanent='true'
        data-kt-menu-placement='bottom-end'
        ref={element}
      >
        <div
          className='d-flex align-items-center'
          data-kt-search-element='toggle'
          id='kt_header_search_toggle'
        >
          <div className='btn btn-icon w-30px h-30px w-md-40px h-md-40px'>
            <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1 search-icon-header' />
          </div>
        </div>

        <div
          data-kt-search-element='content'
          className='menu menu-sub menu-sub-dropdown p-7 w-325px w-md-375px'
        >
          <div className={`${menuState === 'main' ? '' : 'd-none'}`} ref={wrapperElement} data-kt-search-element='wrapper'>
            <form
              data-kt-search-element='form'
              className='w-100 position-relative'
              autoComplete='off'
              onSubmit={e => { e.preventDefault(); }}>
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='searchBoxSVG svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0'
              />

              <DebounceInput
                minLength={2}
                debounceTimeout={300}
                className='form-control form-control-flush ps-10 searchInput'
                name='search'
                placeholder={intl.formatMessage({ id: 'POPLEADS.SEARCH_COURSES' })}
                data-kt-search-element='input'
                onChange={(input) => {
                  searchCourse(input.target.value);
                }}
              />

              <span
                className='position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-1'
                id="spinnerSearch"
                data-kt-search-element='spinner'
              >
                <span className='spinner-border h-15px w-15px align-middle text-gray-400' />
              </span>

              <span
                className='btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none'
                data-kt-search-element='clear'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon-2 svg-icon-lg-1 me-0'
                />
              </span>

              {/*<div
                className='position-absolute top-50 end-0 translate-middle-y'
                data-kt-search-element='toolbar'
              >
                <div
                  data-kt-search-element='preferences-show'
                  className='btn btn-icon w-20px btn-sm btn-active-color-primary me-1'
                  data-bs-toggle='tooltip'
                  onClick={() => { setMenuState("preferences") }}
                  title='Show search preferences'
                >
                  <KTSVG path='/media/icons/duotune/coding/cod001.svg' className='svg-icon-1' />
                </div>

                <div
                  data-kt-search-element='advanced-options-form-show'
                  className='btn btn-icon w-20px btn-sm btn-active-color-primary'
                  data-bs-toggle='tooltip'
                  onClick={() => { setMenuState("advanced") }}
                  title='Show more search options'
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-2' />
                </div>
              </div>*/}
            </form>


            <div ref={resultsElement} data-kt-search-element='main'>
              {searchResults.length > 0 &&
                <div className='d-flex flex-stack fw-bold mb-4'>
                  <span className='text-muted tm-color fs-6 me-2'>{intl.formatMessage({ id: 'POPLEADS.SEARCH_RESULTS' })} </span>
                </div>
              }

              <div className='scroll-y mh-200px mh-lg-325px'>

                {searchResults.map((result: any) =>
                  <div className='d-flex align-items-center mb-5'>
                    <div className='symbol symbol-40px me-4'>
                      <span className='symbol-label bg-light' style={{ backgroundImage: 'url("' + (result.imgURL ? result.imgURL : 'https://www.leoron.com/wp-content/uploads/CFM-2.jpg') + '")' }}>
                      </span>
                    </div>

                    <div className='d-flex flex-column'>
                      <Link to={"/my-courses/detail/" + result.id} onClick={() => {
                        const body = document.getElementById("kt_content");
                        body && body.classList.remove('blurContent')
                        MenuComponent.hideDropdowns(undefined);
                      }}>
                        <span className='fs-6 fw-bold'>
                          {result.ad}
                        </span>
                      </Link>
                      {/*<span className='fs-7 text-muted fw-bold'>#45789</span>*/}
                    </div>
                  </div>
                )}

              </div>
            </div>

            <div ref={emptyElement} data-kt-search-element='empty' className='text-center d-none'>
              <div className='pt-10 pb-10'>
                <KTSVG
                  path='/media/icons/duotune/files/fil024.svg'
                  className='svg-icon-4x opacity-50 not-found'
                />
              </div>

              <div className='pb-15 fw-bold'>
                <h3 className='text-gray-300 fs-5 mb-2'>{intl.formatMessage({ id: 'POPLEADS.SEARCH_NO_RESULTS' })}</h3>
                <div className='text-muted tm-color fs-7'>{intl.formatMessage({ id: 'POPLEADS.SEARCH_NO_RESULTS_INFO' })}</div>
              </div>
            </div>
          </div>

          <form className={`pt-1 ${menuState === 'advanced' ? '' : 'd-none'}`} >
            <h3 className='fw-bold text-dark mb-7'>Advanced Search</h3>

            <div className='mb-5'>
              <input
                type='text'
                className='form-control form-control-sm form-control-solid'
                placeholder='Contains the word'
                name='query'
              />
            </div>

            <div className='mb-5'>
              <div className='nav-group nav-group-fluid'>
                <label>
                  <input type='radio' className='btn-check' name='type' value='has' defaultChecked />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                    All
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='type' value='users' />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    Users
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='type' value='orders' />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    Orders
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='type' value='projects' />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    Projects
                  </span>
                </label>
              </div>
            </div>

            <div className='mb-5'>
              <input
                type='text'
                name='assignedto'
                className='form-control form-control-sm form-control-solid'
                placeholder='Assigned to'
              />
            </div>

            <div className='mb-5'>
              <input
                type='text'
                name='collaborators'
                className='form-control form-control-sm form-control-solid'
                placeholder='Collaborators'
              />
            </div>

            <div className='mb-5'>
              <div className='nav-group nav-group-fluid'>
                <label>
                  <input type='radio' className='btn-check' name='attachment' value='has' defaultChecked />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                    Has attachment
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='attachment' value='any' />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    Any
                  </span>
                </label>
              </div>
            </div>

            <div className='mb-5'>
              <select
                name='timezone'
                aria-label='Select a Timezone'
                data-control='select2'
                data-placeholder='date_period'
                className='form-select form-select-sm form-select-solid'
              >
                <option value='next'>Within the next</option>
                <option value='last'>Within the last</option>
                <option value='between'>Between</option>
                <option value='on'>On</option>
              </select>
            </div>

            <div className='row mb-8'>
              <div className='col-6'>
                <input
                  type='number'
                  name='date_number'
                  className='form-control form-control-sm form-control-solid'
                  placeholder='Lenght'
                />
              </div>

              <div className='col-6'>
                <select
                  name='date_typer'
                  aria-label='Select a Timezone'
                  data-control='select2'
                  data-placeholder='Period'
                  className='form-select form-select-sm form-select-solid'
                >
                  <option value='days'>Days</option>
                  <option value='weeks'>Weeks</option>
                  <option value='months'>Months</option>
                  <option value='years'>Years</option>
                </select>
              </div>
            </div>

            <div className='d-flex justify-content-end'>
              <button
                onClick={(e) => { e.preventDefault(); setMenuState("main") }}
                className='btn btn-sm btn-light fw-bolder btn-active-light-primary me-2'
              >
                Cancel
              </button>

              <a
                href='/#'
                className='btn btn-sm fw-bolder btn-primary'
                data-kt-search-element='advanced-options-form-search'
              >
                Search
              </a>
            </div>
          </form>

          <form className={`pt-1 ${menuState === 'preferences' ? '' : 'd-none'}`} >
            <h3 className='fw-bold text-dark mb-7'>Search Preferences</h3>

            <div className='pb-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                  Projects
                </span>

                <input className='form-check-input' type='checkbox' value='1' defaultChecked />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                  Targets
                </span>
                <input className='form-check-input' type='checkbox' value='1' defaultChecked />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                  Affiliate Programs
                </span>
                <input className='form-check-input' type='checkbox' value='1' />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                  Referrals
                </span>
                <input className='form-check-input' type='checkbox' value='1' defaultChecked />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>Users</span>
                <input className='form-check-input' type='checkbox' />
              </label>
            </div>

            <div className='d-flex justify-content-end pt-7'>
              <button
                onClick={(e) => { e.preventDefault(); setMenuState("main") }}
                className='btn btn-sm btn-light fw-bolder btn-active-light-primary me-2'
              >
                Cancel
              </button>
              <button className='btn btn-sm fw-bolder btn-primary'>
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export { Search }
